import React, { useEffect } from "react"
import { useRedirectUrl } from "../../application-data/redirect"
import Loader from "../../components/common/loader.component"
import Layout from "../../components/layout"
import { Path } from "../../navigation/constants/routes"
import { useAuthentication } from "../../hooks/use-authentication.hook"
import { graphql } from "gatsby"
import { Menu } from "../../navigation/types/menu.interface"
import { AppFunctionComponent, TextBuilder } from "../../types"

type RedirectProps = {
  data: {
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
  }
}

const RedirectPage: AppFunctionComponent<RedirectProps> = ({
  data: {
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
}) => {
  const [redirectValue] = useRedirectUrl()
  const pending = useAuthentication()

  const redirect = (url: string | Path | null) => {
    window.location.replace((url || Path.Home).toString())
  }

  useEffect(() => {
    if (!pending) {
      redirect(redirectValue)
    }
  }, [pending])

  return (
    <Layout
      promobarContent={promobarContent}
      topNavigationItems={topNavigationItems}
      footerNavigationItems={footerNavigationItems}
    >
      <Loader />
    </Layout>
  )
}

export const query = graphql`
  query RedirectQuery {
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: "en-US" }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: "en-US" }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "63Ao7XUYTc7UtDipcRpwoc" }
      node_locale: { eq: "en-US" }
    ) {
      text {
        raw
      }
      node_locale
    }
  }
`

export default RedirectPage
